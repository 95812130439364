import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";
import { NologinGuard } from "./guards/nologin.guard";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule) },
  //{ path: 'home', loadChildren: './home/home.module#HomePageModule', canActivate : [AuthGuard] },
  
  { path: 'login', loadChildren: './login/login.module#LoginPageModule', canActivate : [NologinGuard]},
  { path: 'forgot', loadChildren: './forgot/forgot.module#ForgotPageModule', canActivate : [NologinGuard]},
  { path: 'signup', loadChildren: './signup/signup.module#SignupPageModule', canActivate : [NologinGuard]},
  { path: 'menu', loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule), canActivate : [AuthGuard]},
  // Internal pages  
  { path: 'addcar', loadChildren: () => import('./pages/addcar/addcar.module').then( m => m.AddcarPageModule) },
  { path: 'mycar', loadChildren: () => import('./pages/mycar/mycar.module').then( m => m.MycarPageModule) },
  { path: 'addgas/:lastlog/:vin', loadChildren: () => import('./pages/addgas/addgas.module').then( m => m.AddgasPageModule) },
  { path: 'gasrecord', loadChildren: () => import('./pages/gasrecord/gasrecord.module').then( m => m.GasrecordPageModule) },
  { path: 'gasrecordbreakdown/:grid/:vin', loadChildren: () => import('./pages/gasrecordbreakdown/gasrecordbreakdown.module').then( m => m.GasrecordbreakdownPageModule) },
  { path: 'cheapgas', loadChildren: () => import('./pages/cheapgas/cheapgas.module').then( m => m.CheapgasPageModule) },
  { path: 'bestperformance', loadChildren: () => import('./pages/bestperformance/bestperformance.module').then( m => m.BestperformancePageModule) },
  { path: 'lowerperformance', loadChildren: () => import('./pages/lowerperformance/lowerperformance.module').then( m => m.LowerperformancePageModule) },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule) },
  {
    path: 'cargas',
    loadChildren: () => import('./pages/cargas/cargas.module').then( m => m.CargasPageModule)
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
  // { preloadingStrategy: PreloadAllModules }
})
export class AppRoutingModule { }
