// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*
export const environment = {
  production: true,
  config: {
    apiKey: 'AIzaSyAg2liX3V7ihsH1WmKeD9S1mipBIPUKNVU',
    authDomain: 'com.automovilapp.login.master',
    databaseURL: 'https://appmiauto-6b697.firebaseio.com',
    projectId: 'appmiauto-6b697',
    storageBucket: 'appmiauto-6b697.appspot.com',
    messagingSenderId: '1066337616196'
  }
};
*/
export const environment = {
  production: true,
  config: {
    apiKey: "AIzaSyDwTgM_HefrWCB2avS4w94csa8jnplpfSM",
    authDomain: "kph-7d38b.firebaseapp.com",
    databaseURL: "https://kph-7d38b.firebaseio.com",
    projectId: "kph-7d38b",
    storageBucket: "kph-7d38b.appspot.com",
    messagingSenderId: "827282940309",
    appId: "1:827282940309:web:5e54a94a53c46e8a4c569c",
    measurementId: "G-4ED2W8XVWG"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
